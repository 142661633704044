import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';


@Component({
  selector: 'stars',
  templateUrl: './stars.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {'class': 'block w-fit-content'}
})
export class StarsComponent implements OnInit {

  @Input() rating = 80;
  @Input() size = 20;

  stars = [].constructor(5);

  constructor() {
  }

  ngOnInit(): void {
  }


}
