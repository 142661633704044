<div class="relative min-w-[fit-content]">
  <div class="grid grid-cols-5">
    <svg class="block text-neutral-300" *ngFor="let star of stars" [style.width.px]="size" [style.height.px]="size" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z"/>
    </svg>
  </div>
  <div class="absolute top-0 left-0 overflow-hidden" [style.width.%]="rating">
    <div class="grid grid-cols-5" [style.width.px]="size*5">
      <svg class="block h-4 w-4 text-primary-300" *ngFor="let star of stars" [style.width.px]="size" [style.height.px]="size" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z"/>
      </svg>
    </div>
  </div>
</div>
